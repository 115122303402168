import * as React from "react";
import ReactDOM from "react-dom";
import ReusableAlert, { AlertSeverity } from "./Alerts";

type Props = {
  open: boolean;
  onClose: () => void;
  severity: AlertSeverity;
  message: string;
};

const alertUtils = ({ open, onClose, severity, message }: Props) => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  };

  const alertComponent = (
    <ReusableAlert
      open={open}
      onClose={handleClose} // Use the local handleClose function here
      severity={severity}
      message={message}
    />
  );

  ReactDOM.render(alertComponent, container);
};

export const alertError = (message: string) => {
  alertUtils({ open: true, onClose: () => {}, severity: "error", message });
};

export const alertSuccess = (message: string) => {
  alertUtils({ open: true, onClose: () => {}, severity: "success", message });
};

export const alertWarning = (message: string) => {
  alertUtils({ open: true, onClose: () => {}, severity: "warning", message });
};




// test code with jest 


/*


import React from 'react';
import ReactDOM from 'react-dom/client';
import ReusableAlert from './Alerts';

export const alertUtils = (alertComponent: React.ReactElement) => {
  const container = document.createElement('div');
  document.body.appendChild(container);

  const root = ReactDOM.createRoot(container);
  root.render(alertComponent);

  return () => root.unmount();
};

export const alertError = (message: string) => {
  const alertComponent = (
    <ReusableAlert
      open={true}
      onClose={() => {}}
      severity="error"
      message={message}
    />
  );
  return alertUtils(alertComponent);
};

export const alertSuccess = (message: string) => {
  const alertComponent = (
    <ReusableAlert
      open={true}
      onClose={() => {}}
      severity="success"
      message={message}
    />
  );
  return alertUtils(alertComponent);
};


*/